/*
 * @Author: xiaosihan
 * @Date: 2024-11-14 14:38:29
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-11-14 20:10:08
 */

import { DataTexture, Mesh, MeshStandardMaterial, Object3D, Texture } from 'three';
import GLTFExporter from './GLTFExporter.es.js';
import utils from '@/utils/utils.js';
import extractMaterial from './extractMaterial.js';
import disarrangeTexture from './disarrangeTexture.js';
const gltfExporter = new GLTFExporter();

// 导出matgltf
export default async function exportMtagltf(Object3d: Object3D) {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const materials = extractMaterial(Object3d) as Array<MeshStandardMaterial>;

  // 在这里处理贴图叠加颜色的情况 以及图片是  dataTexture 时导出异常的情况
  for (let material of materials) {
    const mapKey = Object.keys(material).filter((key) => /map$/i.test(key)) as unknown as Array<keyof MeshStandardMaterial>;
    for (let param of mapKey) {
      if (material[param]) {
        if (!(material[param] as Texture).source.data) {
          (material[param] as Texture | null) = null;
        } else if (material[param] instanceof DataTexture) {
          (material[param] as DataTexture).flipY = false;
        }
      }
    }
  }

  // 如果 纹理是 DataTexture 的话 uv 就上下翻转一下
  Object3d.traverse((obj) => {
    const mesh = obj as Mesh;
    if (mesh.isMesh) {
      const materials = extractMaterial(mesh) as Array<MeshStandardMaterial>;
      if (materials[0] && materials[0].map instanceof DataTexture) {
        const uv = mesh.geometry.getAttribute('uv');
        if (uv) {
          for (let i = 0; i < uv.count; i++) {
            uv.setY(i, 1 - uv.getY(i));
          }
        }
      }
    }
  });

  return new Promise<ArrayBuffer>(async (resolve, reject) => {
    const gltfjson = (await gltfExporter
      .parseAsync(Object3d, {
        trs: true,
        onlyVisible: true,
        truncateDrawRange: true,
        binary: false,
        embedImages: true, // 内嵌纹理
        maxTextureSize: 4096,
        animations: Object3d.animations,
        forceIndices: false, // 为几何体生成索引
        includeCustomExtensions: false,
      })
      .catch((err) => {
        reject(err);
      })) as { [key: string]: any };

    //来源信息
    Object.assign(gltfjson.asset, {
      generator: 'meta3D',
      version: '2.0',
    });

    //gltfjson.textureKey  = '[5,7,9,1,2,3]'

    //图片加密
    for (const image of gltfjson.images || []) {
      const disarrangeFile = await disarrangeTexture(image.uri);
      utils.downloadFile(disarrangeFile);
      image.uri = 'xxxx.oss.xxxx.png'
      // image.uri
    }
    // gltfjson.images &&
    //   gltfjson.images.map((image: any, i: any) => {
    //     var suffix = image.uri.match(/data:image\/([^;]+);base64,/i)[1].toLowerCase();
    //     const name = `${utils.uuid()}.${suffix}`;
    //     zip.file(name, image.uri.split(',')[1], { base64: true }); // 假设imgData是图片的base64编码
    //     image.uri = name;
    //   });

    // gltfjson.buffers &&
    //   gltfjson.buffers.map((buffer: any, i: any) => {
    //     const name = `${utils.uuid()}.bin`;
    //     zip.file(name, buffer.uri.split(',')[1], { base64: true }); // 顶点的二进制数据
    //     buffer.uri = name;
    //   });

    // zip.file(`${utils.uuid()}.gltf`, JSON.stringify(gltfjson, null, 2));
  });
}
