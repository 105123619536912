/*
 * @Author: xiaosihan
 * @Date: 2024-08-20 15:43:42
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-10-10 11:17:18
 */

import { DataTexture, Mesh, MeshStandardMaterial, Object3D, Texture } from 'three';
// import { GLTFExporter } from 'three/examples/jsm/Addons.js';
import GLTFExporter from './GLTFExporter.es.js';
import extractMaterial from './extractMaterial.js';
const gltfExporter = new GLTFExporter();

// 导出模型
export default async function exportGlb(Object3d: Object3D) {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const materials = extractMaterial(Object3d) as Array<MeshStandardMaterial>;

  // 在这里处理贴图叠加颜色的情况 以及图片是  dataTexture 时导出异常的情况
  for (let material of materials) {
    const mapKey = Object.keys(material).filter((key) => /map$/i.test(key)) as unknown as Array<keyof MeshStandardMaterial>;
    for (let param of mapKey) {
      if (material[param]) {
        if (!(material[param] as Texture).source.data) {
          (material[param] as Texture | null) = null;
        }
      }
    }
  }

  // 如果 纹理是 DataTexture 的话 uv 就上下翻转一下
  Object3d.traverse((obj) => {
    const mesh = obj as Mesh;
    if (mesh.isMesh) {
      const materials = extractMaterial(mesh) as Array<MeshStandardMaterial>;
      if (materials[0] && materials[0].map instanceof DataTexture) {
        const uv = mesh.geometry.getAttribute('uv');
        if (uv) {
          for (let i = 0; i < uv.count; i++) {
            uv.setY(i, 1 - uv.getY(i));
          }
        }
      }
    }
  });

  return gltfExporter.parseAsync(Object3d, {
    trs: true,
    onlyVisible: true,
    truncateDrawRange: true,
    binary: true,
    embedImages: true,
    maxTextureSize: 4096,
    animations: Object3d.animations,
    forceIndices: false, // 为几何体生成索引
    includeCustomExtensions: false,
  });
}
