/*
 * @Author: xiaosihan
 * @Date: 2024-11-14 18:10:14
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-11-15 17:54:47
 */

import utils from '@/utils/utils';
import {
  BufferGeometry,
  Float32BufferAttribute,
  Material,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  OrthographicCamera,
  RepeatWrapping,
  Scene,
  ShaderChunk,
  ShaderMaterial,
  Texture,
  WebGLRenderer,
} from 'three';

// 对称加密秘钥
const key = '3bkRxxiQBx17t5kKubaGoU';

// 像素块的交换顺序
export const blockSort = [
  177, 34, 109, 216, 144, 155, 28, 135, 131, 27, 14, 133, 148, 20, 1, 26, 5, 211, 43, 0, 213, 35, 223, 207, 42, 4, 166, 21, 227, 195, 13, 51, 147, 23, 63, 16, 54, 41, 36, 44, 49,
  61, 45, 237, 9, 181, 52, 167, 58, 33, 151, 226, 192, 215, 19, 208, 31, 134, 165, 22, 40, 55, 204, 158, 62, 56, 182, 53, 37, 185, 243, 30, 178, 169, 38, 209, 32, 149, 60, 57, 8,
  24, 246, 10, 183, 164, 29, 2, 136, 231, 129, 39, 15, 159, 11, 152, 3, 25, 188, 141, 140, 222, 7, 18, 12, 200, 47, 174, 196, 50, 17, 46, 236, 199, 6, 59, 48, 171, 88, 219, 64, 79,
  100, 132, 232, 102, 172, 99, 123, 128, 82, 105, 85, 113, 126, 110, 187, 139, 198, 252, 143, 175, 173, 179, 78, 180, 119, 74, 193, 206, 124, 242, 191, 184, 153, 249, 163, 75, 154,
  190, 138, 94, 203, 170, 157, 104, 197, 202, 137, 115, 127, 66, 70, 255, 221, 103, 65, 168, 150, 212, 205, 92, 238, 93, 156, 117, 111, 98, 217, 67, 87, 241, 189, 121, 91, 106, 76,
  228, 162, 97, 214, 71, 69, 95, 248, 68, 108, 210, 220, 81, 253, 130, 73, 89, 116, 218, 90, 80, 161, 201, 83, 225, 84, 176, 86, 125, 235, 120, 101, 72, 160, 142, 146, 145, 77,
  247, 96, 114, 224, 244, 230, 251, 118, 107, 234, 240, 112, 233, 245, 229, 239, 250, 194, 186, 254, 122,
];

const renderer = new WebGLRenderer({
  alpha: true, // 透明背景
  antialias: false, // 抗锯齿
  precision: 'highp', //precision:highp/mediump/lowp着色精度选择
  logarithmicDepthBuffer: true, // 对数深度缓冲区 避免当2个面挨的很近时闪烁
  preserveDrawingBuffer: false, // 是否保存绘图缓冲
  stencil: false, // 模版
});

class FullScreenQuad extends Scene {
  constructor() {
    super();
  }

  mesh = (() => {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new Float32BufferAttribute([-1, 3, 0, -1, -1, 0, 3, -1, 0], 3));
    geometry.setAttribute('uv', new Float32BufferAttribute([0, 2, 0, 0, 2, 0], 2));
    const mesh = new Mesh<BufferGeometry, Material>(geometry, new MeshBasicMaterial());
    this.add(mesh);
    return mesh;
  })();

  orthographicCamera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);

  render(renderer: WebGLRenderer, material: Material) {
    this.mesh.material = material;
    renderer.render(this, this.orthographicCamera);
  }
}

// 创建一个渲染工具 用于处理纹理
const fsQuad = new FullScreenQuad(); // 渲染辅助器

// 打乱着色器
const disarrangeShader = new ShaderMaterial({
  uniforms: {
    blockSort: { value: blockSort }, //按方块打乱的顺序
    blockSortRow: { value: 16 }, //方块的行数
    blockSortCol: { value: 16 }, //方块的列数
    map: { value: null }, // 贴图纹理
    opacity: { value: 1 }, // 透明度
  },
  vertexShader: `
        ${ShaderChunk.common}
        ${ShaderChunk.logdepthbuf_pars_vertex}
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            ${ShaderChunk.logdepthbuf_vertex}
        }
    `,
  fragmentShader: `
        uniform int blockSortRow; //方块的行数
        uniform int blockSortCol; //方块的列数
        uniform int blockSort[256]; //方块的列数
        ${ShaderChunk.logdepthbuf_pars_fragment}
        uniform float opacity;
        uniform sampler2D map;
        varying vec2 vUv;
        // 按方块打乱uv坐标
        vec2 disarrngeUV(vec2 uv){
        
            vec2 newUV = vec2(0.0, 0.0);
            float rowWidth = 1.0 / float(blockSortRow); // 每行宽度
            float colWidth = 1.0 / float(blockSortCol); // 每列宽度

            // 计算原始的X和Y坐标(整数部分)
            float originXf = uv.x / rowWidth; // 将uv.x转换为对应的行索引(浮点数)
            float originYf = uv.y / colWidth; // 将uv.y转换为对应的列索引(浮点数)
            
            int originX = int(originXf); // 取整得到行索引
            int originY = int(originYf); // 取整得到列索引
            
            // 计算方块需要交换的位置
            int blockSortIndex = originY * blockSortRow + originX; // 确保这个值不超过数组大小
            int index = blockSort[blockSortIndex];

            // 使用计算出的索引来设置新的UV坐标
            newUV.x = mod(uv.x , rowWidth) + (rowWidth * mod( float(index), float(blockSortRow)));
            newUV.y = mod(uv.y , colWidth) + (colWidth * floor(float(index)/float(blockSortCol)));

            return newUV;
        }
            
        void main() {
            gl_FragColor = texture2D(map, disarrngeUV(vUv));
            gl_FragColor.a *= opacity;
            ${ShaderChunk.logdepthbuf_fragment}
        }
    `,
  depthTest: true,
  depthWrite: true,
  transparent: true,
});

// 打乱base64 图片并输出文件对象
export default async function disarrangeTexture(base64Data: string | File, block_sort: Array<number> = blockSort): Promise<File> {
  // 创建Image对象
  const img = new Image();
  // 当图片加载完成后执行
  await new Promise((resolve, reject) => {
    img.onload = resolve;

    if (base64Data instanceof File) {
      img.src = URL.createObjectURL(base64Data);
    } else {
      img.src = base64Data;
    }
  });

  const { naturalWidth: width, naturalHeight: height } = img;

  renderer.setSize(width, height);

  const texture = new Texture(img);
  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.minFilter = NearestFilter; // 设置缩小过滤器为最近邻插值
  texture.magFilter = NearestFilter; // 设置放大过滤器为最近邻插值
  texture.needsUpdate = true; // 通知three.js纹理需要更新
  await new Promise((resolve) => setTimeout(resolve, 1000));
  disarrangeShader.uniforms.blockSort.value = block_sort;
  disarrangeShader.uniforms.map.value = texture;
  fsQuad.render(renderer, disarrangeShader);

  //纹理回收
  texture.dispose();

  // 将canvas的内容导出为Blob对象
  const file = await new Promise<File>((resolve, reject) => {
    renderer.domElement.toBlob((blob) => {
      const file = new File([blob!], `${utils.uuid()}.png`, { type: 'image/png' });
      resolve(file);
    }, 'image/png');
  });

  return file as unknown as File;
}
