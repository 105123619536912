/*
 * @Author: xiaosihan
 * @Date: 2024-09-25 17:11:46
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-09-28 18:03:51
 */

import uniq from 'lodash-es/uniq';
import { Material, Mesh, Object3D } from 'three';

// 递归遍历材质对象
export default function extractMaterial(object: Object3D) {
  let materials: Array<Material> = [];
  object.traverse((o) => {
    const mesh = o as Mesh;
    if (mesh.isMesh) {
      const material = mesh.material;
      if (material instanceof Array) {
        materials.push(...material);
      } else {
        materials.push(material);
      }
    }
  });
  materials = uniq(materials);
  return materials;
}
