/*
 * @Author: xiaosihan
 * @Date: 2024-08-20 15:43:42
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-10-10 11:17:30
 */

import utils from '@/utils/utils';
import JSZip from 'jszip';
import { Color, DataTexture, Mesh, MeshStandardMaterial, Object3D, Texture } from 'three';
// import { GLTFExporter } from 'three/examples/jsm/Addons.js';
import GLTFExporter from './GLTFExporter.es.js';
import extractMaterial from './extractMaterial.js';

const gltfExporter = new GLTFExporter();
// 导出模型
export default async function exportGlTF(Object3d: Object3D) {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  const materials = extractMaterial(Object3d) as Array<MeshStandardMaterial>;

  // 在这里处理贴图叠加颜色的情况 以及图片是  dataTexture 时导出异常的情况
  for (let material of materials) {
    const mapKey = Object.keys(material).filter((key) => /map$/i.test(key)) as unknown as Array<keyof MeshStandardMaterial>;
    for (let param of mapKey) {
      if (material[param]) {
        if (!(material[param] as Texture).source.data) {
          (material[param] as Texture | null) = null;
        } else if (material[param] instanceof DataTexture) {
          (material[param] as DataTexture).flipY = false;
        }
      }
    }
  }

  // 如果 纹理是 DataTexture 的话 uv 就上下翻转一下
  Object3d.traverse((obj) => {
    const mesh = obj as Mesh;
    if (mesh.isMesh) {
      const materials = extractMaterial(mesh) as Array<MeshStandardMaterial>;
      if (materials[0] && materials[0].map instanceof DataTexture) {
        const uv = mesh.geometry.getAttribute('uv');
        if (uv) {
          for (let i = 0; i < uv.count; i++) {
            uv.setY(i, 1 - uv.getY(i));
          }
        }
      }
    }
  });

  return new Promise<ArrayBuffer>(async (resolve, reject) => {
    const gltfjson = (await gltfExporter
      .parseAsync(Object3d, {
        trs: true,
        onlyVisible: true,
        truncateDrawRange: true,
        binary: false,
        embedImages: true, // 内嵌纹理
        maxTextureSize: 4096,
        animations: Object3d.animations,
        forceIndices: false, // 为几何体生成索引
        includeCustomExtensions: false,
      })
      .catch((err) => {
        reject(err);
      })) as { [key: string]: any };

    // 创建一个新的JSZip实例
    var zip = new JSZip();

    Object.assign(gltfjson.asset, {
      generator: 'meta3D',
      version: '2.0',
    });

    gltfjson.buffers &&
      gltfjson.buffers.map((buffer: any, i: any) => {
        const name = `${utils.uuid()}.bin`;
        zip.file(name, buffer.uri.split(',')[1], { base64: true }); // 顶点的二进制数据
        buffer.uri = name;
      });

    gltfjson.images &&
      gltfjson.images.map((image: any, i: any) => {
        var suffix = image.uri.match(/data:image\/([^;]+);base64,/i)[1].toLowerCase();
        const name = `${utils.uuid()}.${suffix}`;
        zip.file(name, image.uri.split(',')[1], { base64: true }); // 假设imgData是图片的base64编码
        image.uri = name;
      });

    // 最后把 gltf json 加入到zip
    zip.file(`${utils.uuid()}.gltf`, JSON.stringify(gltfjson, null, 2));

    // 生成ZIP文件并转换为ArrayBuffer
    const zipArrayBuffer = await zip.generateAsync({ type: 'arraybuffer' });

    resolve(zipArrayBuffer as ArrayBuffer);
  });
}
