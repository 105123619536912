/*
 * @Author: xiaosihan
 * @Date: 2024-08-20 19:32:28
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-09-13 17:35:57
 */

import { Object3D } from 'three';
import { OBJExporter } from 'three/examples/jsm/Addons.js';
const objExporter = new OBJExporter();

// 导出 obj
export default function exportObj(Object3d: Object3D) {
  const objString = objExporter.parse(Object3d);
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(objString);
  return uint8Array.buffer;
}
