/*
 * @Author: xiaosihan
 * @Date: 2024-11-15 17:38:35
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-11-15 17:52:57
 */

import { clone, cloneDeep } from 'lodash-es';
import disarrangeTexture from './disarrangeTexture';

// 还原纹理
export default async function restoreTexture(base64Data: string | File, block_sort: Array<number> = []): Promise<File> {
  let new_block_sort = clone(block_sort);
  new_block_sort = new_block_sort.map((v, i) => {
    return block_sort.findIndex((vv) => vv === i);
  });
  return disarrangeTexture(base64Data, new_block_sort);
}
