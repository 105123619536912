import { createApp } from 'vue';
import App from './App.vue';
import formatConvert from './formatConvert/formatConvert';
import utils from './utils/utils';
import disarrangeTexture from './formatConvert/disarrangeTexture';
import restoreTexture from './formatConvert/restoreTexture';
const app = createApp(App);
app.mount('#app');

//监听格式转换的请求消息
window.addEventListener('message', async (e) => {
  const { file, target, type, origin, block_sort } = e.data;

  let newFile;
  if (type === 'formatConvert') {
    // 格式转换服务
    newFile = await formatConvert(file, target);
  } else if (type === 'disarrangeTexture') {
    //图片打乱服务
    newFile = await disarrangeTexture(file, block_sort);
  } else if (type === 'restoreTexture') {
    //图片还原服务
    newFile = await restoreTexture(file, block_sort);
  }

  if (['formatConvert', 'disarrangeTexture', 'restoreTexture'].includes(type) && e.source) {
    e.source.postMessage({ file: newFile, type }, origin);
  }
});
