/*
 * @Author: xiaosihan
 * @Date: 2024-09-13 15:53:02
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-10-10 18:11:10
 */

import { Color, DataTexture, Mesh, MeshStandardMaterial, Object3D, Texture, TextureLoader } from 'three';
import { USDZExporter } from 'three/examples/jsm/Addons.js';
import extractMaterial from './extractMaterial';
import imgMultiplyColor from './imgMultiplyColor';
const exporter = new USDZExporter();

const textLoader = new TextureLoader();

// 导出usdz格式的文件对象
export default async function exportUSDZ(Object3d: Object3D) {
  const usdzArrayData = await exporter.parseAsync(Object3d, {
    maxTextureSize: 4096,
  });
  return usdzArrayData;
}
