/*
 * @Author: xiaosihan
 * @Date: 2024-08-20 14:27:33
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-11-15 17:51:38
 */

import JSZip from 'jszip';
import get from 'lodash-es/get';
import short from 'short-uuid';

export class Utils {
  uuid() {
    // 生成一个短ID
    const shortId = short.generate();
    return shortId;
  }

  /**
   * 多选文件
   */
  async selectFiles(accept = '.jpg,.jpeg,.png,.tga'): Promise<File[]> {
    return new Promise<File[]>((resolve, reject) => {
      const input = document.createElement('input');
      input.style.display = 'none';
      input.type = 'file';
      input.multiple = true;
      input.accept = accept;
      input.onchange = (e: any) => {
        const files = e.target.files as FileList;
        resolve([...files]);
      };
      document.body.append(input);
      input.click();
      setTimeout(() => {
        input.remove();
      }, 100);
    });
  }

  /**
   * 单选文件
   */
  async selectFile(accept = '.jpg,.jpeg,.png,.tga'): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      const input = document.createElement('input');
      input.style.display = 'none';
      input.type = 'file';
      input.multiple = false;
      input.accept = accept;
      input.onchange = (e: any) => {
        const files = e.target.files as PromiseLike<File>;
        resolve((files as unknown as File[])[0]);
      };
      document.body.append(input);
      input.click();
      setTimeout(() => {
        input.remove();
      }, 100);
    });
  }

  /**
   * 多选文件转blob
   */
  async selectFilesToBlobs(accept = '.jpg,.jpeg,.png,.tga'): Promise<string[]> {
    const files = (await this.selectFiles(accept)) as File[];
    return files.map((file) => window.URL.createObjectURL(file));
  }

  /**
   * 单选文件转blob
   */
  async selectFileToBlob(accept = '.jpg,.jpeg,.png,.tga'): Promise<string> {
    const files = (await this.selectFile(accept)) as File;
    return window.URL.createObjectURL(files);
  }

  /**
   * 选择文件夹
   */
  async selectDirectory() {
    return new Promise<Array<File>>((resolve, reject) => {
      const input = document.createElement('input');
      input.style.display = 'none';
      input.type = 'file';
      input.webkitdirectory = true;
      input.onchange = (e: any) => {
        const files = e.target.files as FileList;
        resolve([...files]);
      };
      document.body.append(input);
      input.click();
      setTimeout(() => {
        input.remove();
      }, 100);
    });
  }

  // 下载文件
  async downloadFile(file: File | File[], fileName?: string) {
    if (file instanceof Array) {
      // 创建一个新的JSZip实例
      var zip = new JSZip();
      file.map((f) => {
        zip.file(f.name, f);
      });

      // 生成ZIP文件并转换为ArrayBuffer
      const zipArrayBuffer = await zip.generateAsync({ type: 'arraybuffer' });
      const zipFile = new File([zipArrayBuffer], `${fileName || 'model'}.zip`, { type: 'application/octet-stream' });

      this.downloadFile(zipFile);
    } else {
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.style.display = 'none'; // 隐藏<a>元素
      document.body.appendChild(a); // 将<a>元素添加到文档中（可选，但有助于确保跨浏览器兼容性）
      a.href = url;
      a.download = fileName || file.name; // 如果未提供文件名，则使用文件的原始名称
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }

  // 获取文件名的后缀
  getSuffix(filename: string) {
    const match = filename.match(/\.([^\.]+)$/);
    return match ? match[1].toLowerCase() : '';
  }
  // 从url 中提取文件名
  getFilename(url: string) {
    // 移除URL的查询字符串和哈希部分
    const path = url.split('?')[0].split('#')[0];
    // 使用lastIndexOf和substring从路径中提取文件名
    const filename = path.substring(path.lastIndexOf('/') + 1);
    return filename;
  }

  /**
   * 文件转arrayBuffer
   * 用法  const arrayBuffer = await utils.fileToArrayBuffer(file);
   */
  fileToArrayBuffer(file: File) {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = get(e, ['target', 'result']) as ArrayBuffer;
        resolve(arrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * base64 转 file 文件对象
   */
  base64ToFile(base64String: string, filename: string = this.uuid()) {
    // 去除data URL的前缀，例如data:image/png;base64,
    const dataUrlMatch = base64String.match(/^data:(.*?)(;base64)?,/);
    if (!dataUrlMatch) {
      throw new Error('Invalid base64 string format');
    }
    const mimeString = dataUrlMatch[1]; // 提取MIME类型
    const base64 = base64String.substring(dataUrlMatch[0].length); // 去除MIME类型和base64标识

    // 解码Base64字符串
    const byteCharacters = atob(base64);
    // 创建一个8位的数组，长度等于解码后的字符串的长度
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    // 将8位的数组转换为Uint8Array
    const byteArray = new Uint8Array(byteNumbers);
    // 使用Uint8Array创建Blob对象，并指定MIME类型
    const blob = new Blob([byteArray], { type: mimeString });
    // 使用Blob对象创建File对象
    const file = new File([blob], filename, { type: mimeString });
    return file;
  }

  /** 下载url的文件并转成 file 对象 */
  async downloadUrlFile(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const filename = this.getFilename(url);
    const file = new File([blob], filename, { type: blob.type });
    return file;
  }

  // 设置oss图片的后缀  不传width就表示去掉oss后缀
  ossSuffix(src: string, width?: number) {
    let newSrc = (src || '').replace(/\?x-oss-process=image\/resize\,w\_\d+/, '').replace(/^https?:/, '');
    // oss 后缀参数
    if (width) {
      newSrc += `?x-oss-process=image/resize,w_${width}`;
    }
    return newSrc;
  }
}

const utils = new Utils();

export default utils;
