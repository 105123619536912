/*
 * @Author: xiaosihan
 * @Date: 2024-08-20 11:35:33
 * @Last Modified by: xiaosihan
 * @Last Modified time: 2024-11-14 20:10:00
 */

import utils, { Utils } from '@/utils/utils';
import JSZip from 'jszip';

import { FrontSide, Group, LoadingManager, Material, Mesh, MeshLambertMaterial, MeshPhongMaterial, MeshStandardMaterial, Object3D, Quaternion, Texture } from 'three';
import { ColladaLoader, DRACOLoader, FBXLoader, GLTFLoader, MTLLoader, OBJLoader, STLLoader, TGALoader } from 'three/examples/jsm/Addons.js';
import { USDZLoader as ThreeUSDZLoader } from 'three-usdz-loader';
const threeUSDZLoader = new ThreeUSDZLoader('/three_usdz_loader');

import exportGlb from './exportGlb';
import exportGlTF from './exportGlTF';
import exportObj from './exportObj';
import exportUSDZ from './exportUSDZ';
import exportMtagltf from './exportMtagltf';
// 颜色空间管理
// ColorManagement.workingColorSpace = LinearDisplayP3ColorSpace;

// 每次加载obj都需要设置新的加载器
const loadingManager = new LoadingManager();
const objLoader = new OBJLoader(loadingManager);
const mtlLoader = new MTLLoader(loadingManager);
const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader.setDecoderPath('/three_gltf/'));
const daeLoader = new ColladaLoader(loadingManager);
const fbxLoader = new FBXLoader(loadingManager);
const stlLoader = new STLLoader(loadingManager);
// const usdzLoader = new USDZLoader(loadingManager);
loadingManager.addHandler(/tga$/, new TGALoader());

// 资源列表
const blobs: { [key: string]: string } = {};

// 资源替换
loadingManager.setURLModifier((url) => {
  //全路径匹配资源
  if (blobs[url]) {
    return blobs[url];
  }
  const filename = url.replace(`blob:${location.origin}/`, '').replace(/^\.\//, '');
  let new_url = blobs[filename] || url;
  //如果全匹配没有匹配到资源,就尝试文件名匹配
  if (!blobs[filename]) {
    const blobKey = Object.keys(blobs).find((key) => utils.getFilename(key) === utils.getFilename(filename)) || '';
    new_url = blobs[blobKey] || url;
  }
  return new_url;
});

export const formatConvertUtils = new Utils();

// 格式转换方法
export default async function formatConvert<T extends File | File[]>(
  file: T,
  target: 'gltf' | 'glb' | 'obj' | 'usdz' | 'mtagltf' = 'glb',
  recursion: boolean = false
): Promise<T | undefined> {
  let fileName = '';
  let originFormat = '';

  //如果是多文件就递归调用自己
  if (file instanceof Array) {
    //先把所有的资源都存起来
    file.map((f) => {
      blobs[f.webkitRelativePath.replace(/^[^\/]+\//, '')] = window.URL.createObjectURL(f);
    });
    const fileArray: Array<File> = [];
    for (let f of file) {
      if (['glb', 'gltf', 'obj', 'dae', 'zip', 'fbx'].includes(formatConvertUtils.getSuffix(f.name))) {
        fileName = f.name;
        const new_file = await formatConvert(f as File, target, true);
        if (new_file) {
          fileArray.push(new_file);
        }
      }
    }
    return fileArray as T;
  } else {
    fileName = file.name;
    originFormat = formatConvertUtils.getSuffix(file.name);
  }

  //   if (originFormat === target) {
  //     return file; // 格式相同直接返回
  //   }

  if (!['glb', 'gltf', 'obj', 'dae', 'fbx', 'zip', 'usdz'].includes(formatConvertUtils.getSuffix(file.name))) {
    throw new Error(`暂不支持${formatConvertUtils.getSuffix(file.name)}格式`);
  }

  // 加载模型
  let threeObject3d = await loadMesh(file);

  if (threeObject3d) {
    threeObject3d.traverse((o: Object3D) => {
      const mesh = o as Mesh;
      if (mesh.isMesh) {
        // 把非标准材质都转到标准材质
        mesh.material = toStanderMaterial(mesh.material);
      }
    });
  }

  //   const geometryIndexDatas = await Promise.all(
  //     meshs.map((mesh) => {
  //       return new Promise<GeometryIndexData>((resolve, reject) => {
  //         // const start = new Date().valueOf();

  //         const extractGeometryIndex = new ExtractGeometryIndex();
  //         extractGeometryIndex.onmessage = (e) => {
  //           extractGeometryIndex.terminate();
  //           resolve(e.data);
  //           const conut = mesh.geometry.attributes.position.count;
  //           const time = new Date().valueOf() - start;
  //           console.log(`顶点数${conut} , 时间${time}ms`);
  //         };
  //         extractGeometryIndex.postMessage({
  //           geometry: mesh.geometry,
  //           start: 0,
  //           end: mesh.geometry.attributes.position.count,
  //         });
  //       });
  //     })
  //   );

  //   for (let i in meshs) {
  //     const { index, ...otherAttribute } = geometryIndexDatas[i];
  //     meshs[i].geometry.setIndex(index);
  //     for (let attribute in otherAttribute) {
  //       const { itemSize } = meshs[i].geometry.attributes[attribute];
  //       meshs[i].geometry.setAttribute(attribute, new BufferAttribute(new Float32Array(otherAttribute[attribute]), itemSize));
  //     }
  //   }

  // 导出
  if (threeObject3d) {
    switch (target) {
      case 'glb':
        const glbArrayBuffer = (await exportGlb(threeObject3d)) as ArrayBuffer;
        return new File([glbArrayBuffer], fileName.replace(/\w+$/i, 'glb'), { type: 'application/octet-stream' }) as T;

      case 'gltf':
        const gltfArrayBuffer = await exportGlTF(threeObject3d);
        return new File([gltfArrayBuffer], fileName.replace(/\w+$/i, 'zip'), { type: 'application/octet-stream' }) as T;

      case 'obj':
        const objArrayBuffer = await exportObj(threeObject3d);
        return new File([objArrayBuffer], fileName.replace(/\w+$/i, 'obj'), { type: 'application/octet-stream' }) as T;

      case 'usdz':
        // 先转 glb 再转 usdz
        const glb_arrayBuffer = (await exportGlb(threeObject3d)) as ArrayBuffer;
        const glbFile = new File([glb_arrayBuffer], fileName.replace(/\w+$/i, 'glb'), { type: 'application/octet-stream' }) as T;
        threeObject3d = await loadMesh(glbFile as File);
        const usdzArrayBuffer = await exportUSDZ(threeObject3d!);
        return new File([usdzArrayBuffer], fileName.replace(/\w+$/i, 'usdz'), { type: 'application/octet-stream' }) as T;

      case 'mtagltf':
        const mtagltfArrayBuffer = await exportMtagltf(threeObject3d);
        return new File([mtagltfArrayBuffer], fileName.replace(/\w+$/i, 'mtagltf'), { type: 'application/octet-stream' }) as T;
      default:
        break;
    }
  }

  // 如果是内部递归调用就不清理blob
  if (!recursion) {
    for (let url in blobs) {
      URL.revokeObjectURL(blobs[url]);
      delete blobs[url];
    }
  }

  return undefined;
}

// 把非stander的材质转成stander材质
export function toStanderMaterial<T extends Material | Material[]>(material: T): T {
  if (material instanceof MeshPhongMaterial) {
    const { shininess, normalMap, normalScale, bumpMap, emissiveMap, emissive } = material as MeshPhongMaterial;

    // 创建一个新的标准材质
    const newMaterial = new MeshStandardMaterial();
    // 赋值参数到新的材质上去
    for (let i in newMaterial) {
      if (!['type'].includes(i) && newMaterial.hasOwnProperty(i) && material.hasOwnProperty(i)) {
        //@ts-ignore
        newMaterial[i] = material[i];
      }
    }
    // 参数微调
    emissiveMap && emissive.set(1, 1, 1);
    newMaterial.setValues({
      normalMap: (normalScale.length() <= 0.2 ? null : normalMap) || bumpMap,
      metalness: 0,
      //TODO 还需要考虑到反射颜色的对金属性参数的印象 specular
      roughness: Math.max(0.2, 1 - shininess / 100),
      emissiveMap,
      emissive,
      side: FrontSide,
    });
    return newMaterial as Material as T;
  } else if (material instanceof MeshLambertMaterial) {
    const { normalMap, normalScale, bumpMap, emissiveMap, emissive } = material as MeshLambertMaterial;
    // 创建一个新的标准材质
    const newMaterial = new MeshStandardMaterial();
    // 赋值参数到新的材质上去
    for (let i in newMaterial) {
      if (!['type'].includes(i) && newMaterial.hasOwnProperty(i) && material.hasOwnProperty(i)) {
        //@ts-ignore
        newMaterial[i] = material[i];
      }
    }
    // 参数微调
    emissiveMap && emissive.set(1, 1, 1);
    newMaterial.setValues({
      normalMap: (normalScale.length() <= 0.2 ? null : normalMap) || bumpMap,
      metalness: 0,
      roughness: 1,
      emissiveMap,
      emissive,
      side: FrontSide,
    });
    return newMaterial as Material as T;
  } else if (material instanceof Array) {
    return material.map((m) => toStanderMaterial(m)) as Material[] as T;
  } else {
    material.side = FrontSide;
    return material as T;
  }
}

// 公共的模型加载器
export async function loadMesh(file: File): Promise<Object3D | void> {
  const suffix = formatConvertUtils.getSuffix(file.name);
  const object = await new Promise<Object3D | void>(async (loadMeshResolve, reject) => {
    switch (suffix) {
      case 'zip':
        await JSZip.loadAsync(file).then(async (zip) => {
          for (let filename in zip.files) {
            if (!zip.files[filename].dir) {
              // 不读文件夹
              await zip
                .file(filename)!
                .async('blob')
                .then((blob) => {
                  var blobURL = URL.createObjectURL(blob);
                  blobs[filename] = blobURL;
                });
            }
          }
          // 如果有mtl文件就加载mtl文件
          let mtl = Object.keys(zip.files).find((filename) => /\.mtl$/i.test(filename));
          if (mtl) {
            const materials = await mtlLoader.loadAsync(mtl);
            objLoader.setMaterials(materials);
          } else {
            //@ts-ignore
            objLoader.materials = null;
          }

          //如果有obj就加载obj模型
          let obj = Object.keys(zip.files).find((filename) => /\.obj$/i.test(filename));
          if (obj) {
            const objArraybuffer = await zip.file(obj)?.async('arraybuffer')!;
            const objFile = new File([objArraybuffer], obj, { type: 'application/octet-stream' });
            const objObject3d = await loadMesh(objFile);
            loadMeshResolve(objObject3d);
          }

          //如果有gltf文件就加载gltf文件
          let gltf = Object.keys(zip.files).find((filename) => /\.(gltf|glb)$/i.test(filename));
          if (gltf) {
            const gltfArraybuffer = await zip.file(gltf)?.async('arraybuffer')!;
            const gltfFile = new File([gltfArraybuffer], gltf, { type: 'application/octet-stream' });
            const gltfObject3d = await loadMesh(gltfFile);
            loadMeshResolve(gltfObject3d);
          }

          //如果有dae文件就加载dae文件
          let dae = Object.keys(zip.files).find((filename) => /\.dae$/i.test(filename));
          if (dae) {
            const daeArraybuffer = await zip.file(dae)?.async('arraybuffer')!;
            const daeFile = new File([daeArraybuffer], dae, { type: 'application/octet-stream' });
            const daeObject3d = await loadMesh(daeFile);
            loadMeshResolve(daeObject3d);
          }

          //如果有fbx文件就加载fbx文件
          let fbx = Object.keys(zip.files).find((filename) => /\.fbx$/i.test(filename));
          if (fbx) {
            const fbxArraybuffer = await zip.file(fbx)?.async('arraybuffer')!;
            const fbxFile = new File([fbxArraybuffer], fbx, { type: 'application/octet-stream' });
            const fbxObject3d = await loadMesh(fbxFile);
            loadMeshResolve(fbxObject3d);
          }

          //如果有stl文件就加载stl文件
          let stl = Object.keys(zip.files).find((filename) => /\.stl$/i.test(filename));
          if (stl) {
            const stlArraybuffer = await zip.file(stl)?.async('arraybuffer')!;
            const stlFile = new File([stlArraybuffer], stl, { type: 'application/octet-stream' });
            const stlObject3d = await loadMesh(stlFile);
            loadMeshResolve(stlObject3d);
          }

          //如果有usdz文件就加载usdz文件
          let usdz = Object.keys(zip.files).find((filename) => /\.usdz$/i.test(filename));
          if (usdz) {
            const usdzArraybuffer = await zip.file(usdz)?.async('arraybuffer')!;
            const usdzFile = new File([usdzArraybuffer], usdz, { type: 'application/octet-stream' });
            const usdzObject3d = await loadMesh(usdzFile);
            loadMeshResolve(usdzObject3d);
          }

          //如果有zip文件就加载zip文件
          let zipFile = Object.keys(zip.files).find((filename) => /\.(zip)$/i.test(filename));
          if (zipFile) {
            const zipArraybuffer = await zip.file(zipFile)?.async('arraybuffer')!;
            const zip_file = new File([zipArraybuffer], zipFile, { type: 'application/octet-stream' });
            const zipObject3d = await loadMesh(zip_file);
            loadMeshResolve(zipObject3d);
          }

          if ([obj, gltf, dae, fbx, stl, usdz].every((v) => !v)) {
            reject(new Error(`${file.name}压缩包里没有obj、glb、gltf,dae,fbx,stl,usdz等模型文件`));
          }
        });
        break;

      // case 'rar':
      // await new admZIP(file)

      case 'obj':
        blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        const [objObject3d] = await Promise.all([
          objLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
          new Promise<void>((resolve, reject) => {
            loadingManager.onLoad = async () => {
              await new Promise((resolve) => setTimeout(resolve, 200));
              resolve();
            };
          }),
        ]);

        //把贴图的 flipy 设置为 fasle
        function setFlipYIsFalse<T extends Material | Material[]>(material: T) {
          if (material instanceof Array) {
            material.map((m) => setFlipYIsFalse(m));
          } else {
            const m = material as MeshStandardMaterial;
            const parame: Array<keyof MeshStandardMaterial> = ['map', 'lightMap', 'aoMap', 'emissiveMap', 'bumpMap', 'normalMap', 'displacementMap', 'alphaMap', 'envMap'];
            parame.map((param) => {
              const t = m[param] as Texture | undefined;
              if (t) {
                t.flipY = false;
              }
            });
          }
        }

        objObject3d.traverse((obj) => {
          const mesh = obj as Mesh;

          if (mesh.isMesh) {
            // 如果obj没有材质就给默认的材质
            if (!objLoader.materials) {
              mesh.material = new MeshStandardMaterial({
                metalness: 0.5,
                roughness: 0.5,
              });
            }

            const uv = mesh.geometry.getAttribute('uv');
            if (uv) {
              for (let i = 0; i < uv.count; i++) {
                uv.setY(i, 1 - uv.getY(i));
              }
            }
            // obj只翻转uv不翻转贴图
            setFlipYIsFalse(mesh.material);
          }
        });

        loadMeshResolve(objObject3d);
        break;

      case 'glb':
      case 'gltf':
        blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        const [gltfObject3d] = await Promise.all([
          gltfLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
          new Promise<void>((resolve, reject) => {
            loadingManager.onLoad = async () => {
              await new Promise((resolve) => setTimeout(resolve, 200));
              resolve();
            };
          }),
        ]);
        gltfObject3d.scene.animations = gltfObject3d.animations;
        loadMeshResolve(gltfObject3d.scene);
        break;

      case 'dae':
        blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        const [collada] = await Promise.all([
          daeLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
          new Promise<void>((resolve, reject) => {
            loadingManager.onLoad = async () => {
              await new Promise((resolve) => setTimeout(resolve, 200));
              resolve();
            };
          }),
        ]);
        loadMeshResolve(collada.scene);
        break;

      case 'fbx':
        blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        const [fbxobject] = await Promise.all([
          fbxLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
          new Promise<void>((resolve, reject) => {
            loadingManager.onLoad = async () => {
              await new Promise((resolve) => setTimeout(resolve, 200));
              resolve();
            };
          }),
        ]);
        loadMeshResolve(fbxobject);
        break;

      case 'stl':
        blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        const [stlGeometry] = await Promise.all([
          stlLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
          new Promise<void>((resolve, reject) => {
            loadingManager.onLoad = async () => {
              await new Promise((resolve) => setTimeout(resolve, 200));
              resolve();
            };
          }),
        ]);
        const stlObject3d = new Mesh(
          stlGeometry,
          new MeshStandardMaterial({
            metalness: 0.5,
            roughness: 0.5,
          })
        );
        loadMeshResolve(stlObject3d);
        break;

      case 'usdz':
        const loadedModel = await threeUSDZLoader.loadFile(file, new Group());
        loadedModel.targetGroup.traverse((obj: Object3D) => {
          const mesh = obj as Mesh;
          const quaternion = new Quaternion();
          obj.matrix.decompose(obj.position, quaternion, obj.scale);
          obj.rotation.setFromQuaternion(quaternion);
          if (mesh.isMesh) {
            const material = mesh.material as MeshStandardMaterial;
            material.depthWrite = true;
            material.depthTest = true;
          }
        });
        loadMeshResolve(loadedModel.targetGroup);

        // blobs[file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name] = window.URL.createObjectURL(file);
        // const [usdzObject] = await Promise.all([
        //   usdzLoader.loadAsync(file.webkitRelativePath.replace(/^[^\/]+\//, '') || file.name),
        //   new Promise<void>((resolve, reject) => {
        //     loadingManager.onLoad = async () => {
        //       await new Promise((resolve) => setTimeout(resolve, 200));
        //       resolve();
        //     };
        //   }),
        // ]);
        // usdzObject.traverse((obj) => {
        //   const mesh = obj as Mesh;
        //   if (mesh.isMesh) {
        //     (mesh.material as Material).transparent = true;
        //   }
        // });
        // loadMeshResolve(usdzObject);

        break;

      default:
        break;
    }
  });

  //
  if (object) {
    object.traverse((obj) => {
      const mesh = obj as Mesh;
      if (mesh.isMesh) {
        (mesh.material as Material).depthWrite = true;
        (mesh.material as Material).depthTest = true;
      }
    });
  }

  return object;
}
