<template>
  <div>
  </div>
</template>
<script>
import testdisarrngePNG from './formatConvert/img/testdisarrnge.png';
import utils from './utils/utils.ts';
import formatConvert from './formatConvert/formatConvert.ts';
import disarrangeTexture from './formatConvert/disarrangeTexture.ts';
import restoreTexture from './formatConvert/restoreTexture.ts';
export default {
  name: 'Demo',
  mixins: [], // 混入
  props: {},
  // 注册组件
  components: {},
  // 数据
  data() {
    return {};
  },
  // 监听属性
  watch: {},
  //方法
  methods: {
    //转mtaglb
    async to_mtaglb() {
      const file = await utils.selectFile('.zip,.glb,.gltf,.fbx,.dae,.obj,.usdz'); // 选择本地文件
      const gltffiles = await formatConvert(file, 'mtagltf'); // 转到指定格式
    },
    //打乱纹理测试
    async disarngeImg() {
      const blockSort = [
        177, 34, 109, 216, 144, 155, 28, 135, 131, 27, 14, 133, 148, 20, 1, 26, 5, 211, 43, 0, 213, 35, 223, 207, 42, 4, 166, 21, 227, 195, 13, 51, 147, 23, 63, 16, 54, 41, 36, 44,
        49, 61, 45, 237, 9, 181, 52, 167, 58, 33, 151, 226, 192, 215, 19, 208, 31, 134, 165, 22, 40, 55, 204, 158, 62, 56, 182, 53, 37, 185, 243, 30, 178, 169, 38, 209, 32, 149,
        60, 57, 8, 24, 246, 10, 183, 164, 29, 2, 136, 231, 129, 39, 15, 159, 11, 152, 3, 25, 188, 141, 140, 222, 7, 18, 12, 200, 47, 174, 196, 50, 17, 46, 236, 199, 6, 59, 48, 171,
        88, 219, 64, 79, 100, 132, 232, 102, 172, 99, 123, 128, 82, 105, 85, 113, 126, 110, 187, 139, 198, 252, 143, 175, 173, 179, 78, 180, 119, 74, 193, 206, 124, 242, 191, 184,
        153, 249, 163, 75, 154, 190, 138, 94, 203, 170, 157, 104, 197, 202, 137, 115, 127, 66, 70, 255, 221, 103, 65, 168, 150, 212, 205, 92, 238, 93, 156, 117, 111, 98, 217, 67,
        87, 241, 189, 121, 91, 106, 76, 228, 162, 97, 214, 71, 69, 95, 248, 68, 108, 210, 220, 81, 253, 130, 73, 89, 116, 218, 90, 80, 161, 201, 83, 225, 84, 176, 86, 125, 235,
        120, 101, 72, 160, 142, 146, 145, 77, 247, 96, 114, 224, 244, 230, 251, 118, 107, 234, 240, 112, 233, 245, 229, 239, 250, 194, 186, 254, 122,
      ];
      const file = await utils.selectFile();
      const newFile = await disarrangeTexture(file, blockSort);
      utils.downloadFile(newFile);
    },
    //还原纹理测试
    async restoreImg() {
      const blockSort = [
        177, 34, 109, 216, 144, 155, 28, 135, 131, 27, 14, 133, 148, 20, 1, 26, 5, 211, 43, 0, 213, 35, 223, 207, 42, 4, 166, 21, 227, 195, 13, 51, 147, 23, 63, 16, 54, 41, 36, 44,
        49, 61, 45, 237, 9, 181, 52, 167, 58, 33, 151, 226, 192, 215, 19, 208, 31, 134, 165, 22, 40, 55, 204, 158, 62, 56, 182, 53, 37, 185, 243, 30, 178, 169, 38, 209, 32, 149,
        60, 57, 8, 24, 246, 10, 183, 164, 29, 2, 136, 231, 129, 39, 15, 159, 11, 152, 3, 25, 188, 141, 140, 222, 7, 18, 12, 200, 47, 174, 196, 50, 17, 46, 236, 199, 6, 59, 48, 171,
        88, 219, 64, 79, 100, 132, 232, 102, 172, 99, 123, 128, 82, 105, 85, 113, 126, 110, 187, 139, 198, 252, 143, 175, 173, 179, 78, 180, 119, 74, 193, 206, 124, 242, 191, 184,
        153, 249, 163, 75, 154, 190, 138, 94, 203, 170, 157, 104, 197, 202, 137, 115, 127, 66, 70, 255, 221, 103, 65, 168, 150, 212, 205, 92, 238, 93, 156, 117, 111, 98, 217, 67,
        87, 241, 189, 121, 91, 106, 76, 228, 162, 97, 214, 71, 69, 95, 248, 68, 108, 210, 220, 81, 253, 130, 73, 89, 116, 218, 90, 80, 161, 201, 83, 225, 84, 176, 86, 125, 235,
        120, 101, 72, 160, 142, 146, 145, 77, 247, 96, 114, 224, 244, 230, 251, 118, 107, 234, 240, 112, 233, 245, 229, 239, 250, 194, 186, 254, 122,
      ];
      const url = 'https://jinzhi-dev-data.oss-cn-beijing.aliyuncs.com/meta_resources/testImage/disturb.png';
      const file = await utils.selectFile();
      //   const file = await utils.downloadUrlFile(url);
      const newFile = await restoreTexture(file, blockSort);
      utils.downloadFile(newFile);
    },
  },
  // 计算属性
  computed: {},
  beforeCreate() {}, // 开始创建之前
  created() {}, // 创建完成
  beforeMount() {}, // 组件挂载之前
  mounted() {}, // 组件挂载完成
  beforeUpdate() {}, //  发生改变之前
  updated() {}, // 改变完成
  beforeDestory() {}, // 组件开始销毁
  destroyed() {}, // 组件销毁
};
</script>

<style scoped>
.btn {
  display: inline-block;
  margin-bottom: 4px;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
}
</style>
